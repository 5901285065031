import { $first } from '../nav-tree/utils/selectors';

export const searchButtonCheckboxId = 'searchButtonCheckbox';

export const getSearchCheckbox = () => {
  return $first<HTMLInputElement>(`#${searchButtonCheckboxId}`);
};

export const hideSearchDrawer = () => {
  const searchCheckbox = getSearchCheckbox();

  if (searchCheckbox) searchCheckbox.checked = false;
};
