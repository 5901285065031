import * as uuid from 'uuid';
import type { SegmentEvent } from '@segment/analytics-next';
import { $segment } from '../../store/client';

const segment = $segment.get();

const getSegmentAttributes = (
  item: HTMLElement,
  event: 'global_nav_link_clicked' | 'global_nav_menu_interacted',
  level: 1 | 2 | 3,
  navigation_format: 'header' | 'sidebar',
  entityNameOverride?: string
): SegmentEvent => {
  const properties: SegmentEvent['properties'] = {
    event_id: uuid.v4(),
    entity_name:
      entityNameOverride ??
      (event === 'global_nav_link_clicked'
        ? `global_nav_l${level}_link`
        : `global_nav_l${level}`),
    navigation_format,
    item_level: level,
    item_position: Number(item.getAttribute('data-index')),
    item_copy: item?.innerText.trim(),
  };

  if (item.getAttribute('href'))
    properties.destination_url = item.getAttribute('href');

  if (item.getAttribute('data-index'))
    properties.item_position = Number(item.getAttribute('data-index'));

  if (item.getAttribute('data-l1itemtitle'))
    properties.l1_nav_copy = item.getAttribute('data-l1itemtitle')?.trim();

  if (item.getAttribute('data-l2itemtitle'))
    properties.l2_nav_copy = item.getAttribute('data-l2itemtitle')?.trim();

  const segmentEvent: SegmentEvent = {
    event,
    type: 'track',
    properties,
  };

  return segmentEvent;
};

export const setupClickListeners = (
  item: HTMLElement,
  segmentEvent: 'global_nav_link_clicked' | 'global_nav_menu_interacted',
  level: 1 | 2 | 3,
  navigationFormat?: 'header' | 'sidebar',
  entityNameOverride?: string
) => {
  // Making the call to get getSegmentAttributes here
  // was causing some buttons not to get the current
  // text. I think there was a rendering race-condition
  // at play. This solved it.
  const getSegmentLocal = () =>
    getSegmentAttributes(
      item,
      segmentEvent,
      level,
      navigationFormat ?? 'header',
      entityNameOverride
    );

  if (item.tagName === 'A') {
    const segmentAttributes = getSegmentLocal();
    segment.trackLink(
      item,
      segmentAttributes.event,
      segmentAttributes.properties
    );
  } else {
    item.addEventListener('click', () => {
      const segmentAttributes = getSegmentLocal();
      segment.track(segmentAttributes.event, segmentAttributes.properties);
    });
  }

  item.addEventListener('contextmenu', () => {
    const segmentAttributes = getSegmentLocal();
    segment.track(segmentAttributes.event, segmentAttributes.properties);
  });
};

export const setupMouseListeners = (
  type: 'mouseenter',
  item: HTMLElement,
  segmentEvent: 'global_nav_menu_interacted',
  level: 1 | 2 | 3,
  navigationFormat?: 'header' | 'sidebar'
) => {
  item.addEventListener(type, () => {
    segment.track(
      getSegmentAttributes(
        item,
        segmentEvent,
        level,
        navigationFormat ?? 'header'
      )
    );
  });
};
