export const debounce = <TArgs extends any[]>(
  callback: (...args: TArgs) => void,
  delay?: number
) => {
  let timeoutId: number;

  return () => {
    if (timeoutId) clearTimeout(timeoutId);

    timeoutId = setTimeout(callback, delay);
  };
};
