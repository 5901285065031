import { $first } from './selectors';

const promoVisibleClass = 'promoSpaceWrapperVisible';

const hideCurrent = () => {
  console.log('Hiding visible promos');
  $first(`.${promoVisibleClass}`)?.classList.remove(promoVisibleClass);
};

export const toggleL1PromoSpaceVisibility = (l1Item: HTMLElement) => {
  hideCurrent();

  const idx = Number(l1Item.getAttribute('data-index'));

  const promoSpace = $first(`#promoSpaceWrapper-${idx}`);
  if (promoSpace) {
    console.log('Setting promo visible', promoSpace, promoVisibleClass);
    promoSpace.classList.add(promoVisibleClass);
    console.log('after', $first(`#promoSpaceWrapper-${idx}`));
  }

  return true;
};

export const toggleL2PromoSpaceVisibility = (
  l1Index: number,
  l2Index: number
) => {
  hideCurrent();

  const l2PromoSpace = $first(`#promoSpaceWrapper-${l1Index}-${l2Index}`);
  const l1PromoSpace = $first(`#promoSpaceWrapper-${l1Index}`);

  (l2PromoSpace || l1PromoSpace)?.classList.add(promoVisibleClass);
};
