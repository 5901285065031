import { $showOverlay } from '../../store/client';
import { $first } from './selectors';

export const hideNarrowMenu = () => {
  const sidebarMenuCheckBox = $first<HTMLInputElement>('#sidebarMenuCheckBox');
  if (sidebarMenuCheckBox && sidebarMenuCheckBox.checked) {
    sidebarMenuCheckBox.checked = false;
  }

  const openL2Narrow = $first<HTMLInputElement>('.l1ItemCheckbox');
  if (openL2Narrow && openL2Narrow.checked) {
    openL2Narrow.checked = false;
  }

  $showOverlay.set('hidden');
  return true;
};
